export default {
  "aktualny-jazyk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poľština"])},
  "ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pl"])},
  "extend-web": {
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Więcej informacji na stronie WWW"])}
  },
  "weather": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prognoza pogody jest obecnie niedostępna !"])},
    "at-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godzinowa prognoza pogody"])},
    "direct-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kierunek wiatru"])},
    "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deszcz"])},
    "speed-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prędkość wiatru"])},
    "gust-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podmuchy wiatru"])},
    "press-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciśnienie powietrza"])},
    "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Śnieg"])},
    "sunrise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wschód słońca"])},
    "sunset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zachód słońca"])},
    "cloudy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["słoneczny"])},
    "cloudy-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pół jasne"])},
    "cloudy-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pochmurny"])},
    "cloudy-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prawie pochmurno"])},
    "cloudy-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["całkowicie pochmurno"])}
  },
  "no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ładowarka jest nieaktywna lub w trakcie serwisowania"])},
  "menu": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z POWROTEM"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAPA"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JĘZYKI"])},
    "weath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POGODA"])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REGION INFORMACYJNY"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WIFI"])},
    "con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZŁĄCZE"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POMOC"])},
    "to-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu aplikacji"])},
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta część aplikacji jest chwilowo niedostępna"])},
    "not-wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ładowarka obecnie nie obsługuje współdzielonej sieci WIFI"])}
  },
  "small-display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikacja do swojej funkcjonalności wymaga wyświetlacza o minimalnej rozdzielczości: szerokość 800 i wysokość 600 pikseli"])},
  "daysName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niedziela"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poniedziałek"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wtorek"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Środa"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czwartek"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piątek"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobota"])}
  },
  "monthsName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Styczeń"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luty"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marsz"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwiecień"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Móc"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czerwiec"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lipiec"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sierpień"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrzesień"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Październik"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listopad"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grudzień"])}
  },
  "wifi": {
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Właściciel ładowarki zdecydował się nie udostępniać publicznej sieci WIFI ze względów technicznych"])},
    "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W pobliżu ładowarki dostępna jest wspólna sieć WIFI"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaloguj sie"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasło"])}
  },
  "cms": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przepraszamy, przygotowujemy treść."])}
  },
  "map": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przepraszamy,~ale mapa orientacyjna dla tej ładowarki nie jest obecnie dostępna"])}
  }
}