export default {
  "aktualny-jazyk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ukrajinčina"])},
  "ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ua"])},
  "extend-web": {
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Більше інформації на WEB сторінці"])}
  },
  "weather": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прогноз погоди наразі недоступний !"])},
    "at-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Погодинний прогноз погоди"])},
    "direct-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напрямок вітру"])},
    "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дощ"])},
    "speed-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Швидкість вітру"])},
    "gust-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пориви вітру"])},
    "press-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повітряний тиск"])},
    "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сніг"])},
    "sunrise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cхід сонця"])},
    "sunset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["захід сонця"])},
    "cloudy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cонячно"])},
    "cloudy-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["наполовину ясно"])},
    "cloudy-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["хмарно"])},
    "cloudy-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["майже хмарно"])},
    "cloudy-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["повністю хмарно"])}
  },
  "no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарядний пристрій не неактивний і не знаходиться на обслуговуванні"])},
  "menu": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НАЗАД"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["КАРТА"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["МОВИ"])},
    "weath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ПОГОДА"])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ІНФОРМАЦІЯ"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WIFI"])},
    "con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["РОЗ'ЄМ"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ДОПОМОГА"])},
    "to-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Меню програми"])},
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ця частина програми тимчасово недоступна"])},
    "not-wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарядний пристрій наразі не підтримує спільний WIFI"])}
  },
  "small-display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для роботи програми потрібен дисплей з мінімальною роздільною здатністю: ширина 800 і висота 600 пікселів"])},
  "daysName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hеділя"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Понеділок"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bівторок"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Середа"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Четвер"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["П'ятниця"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Субота"])}
  },
  "monthsName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cічня"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лютий"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Березень"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kвітень"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Може"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Червень"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Липень"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cерпень"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вересень"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жовтень"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Листопад"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Грудень"])}
  },
  "wifi": {
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Власник зарядного пристрою вирішив не ділитися публічною мережею WIFI з технічних причин"])},
    "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спільна мережа WIFI доступна біля зарядного пристрою"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логін"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])}
  },
  "cms": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибачте, ми готуємо контент."])}
  },
  "map": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На жаль,~карта орієнтації для цього зарядного пристрою наразі недоступна"])}
  }
}