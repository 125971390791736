export default {
  "aktualny-jazyk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lotyština"])},
  "ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lv"])},
  "extend-web": {
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vairāk informācijas WEB lapā"])}
  },
  "weather": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laika prognoze šobrīd nav pieejama !"])},
    "at-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laika prognoze stundā"])},
    "direct-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vēja virziens"])},
    "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lietus"])},
    "speed-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vēja ātrums"])},
    "gust-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vēja brāzmas"])},
    "press-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gaisa spiediens"])},
    "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sniegs"])},
    "sunrise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["saullēkts"])},
    "sunset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["saulriets"])},
    "cloudy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["saulains"])},
    "cloudy-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["puse skaidrs"])},
    "cloudy-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["duļķains"])},
    "cloudy-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gandrīz mākoņains"])},
    "cloudy-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pilnīgi duļķains"])}
  },
  "no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lādētājs ir neaktīvs vai tiek apkalpots"])},
  "menu": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATPAKAĻ"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KARTE"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VALODAS"])},
    "weath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LAIKAPSTĀKĻI"])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFORMĀCIJAS REĢIONS"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BEZVADU INTERNETS"])},
    "con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAVIENOTĀJS"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PALĪDZĪBA"])},
    "to-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lietojumprogrammu izvēlne"])},
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šī lietojumprogrammas daļa īslaicīgi nav pieejama"])},
    "not-wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lādētājs pašlaik neatbalsta koplietotu WIFI"])}
  },
  "small-display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lai lietojumprogramma darbotos, ir nepieciešams displejs ar minimālo izšķirtspēju: platums 800 un augstums 600 pikseļi"])},
  "daysName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["svētdiena"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pirmdiena"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["otrdiena"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trešdiena"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ceturtdiena"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["piektdiena"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sestdiena"])}
  },
  "monthsName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["janvārī"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["februāris"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["marts"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aprīlis"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maijā"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jūnijs"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jūlijā"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["augusts"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["septembris"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oktobris"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["novembris"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["decembris"])}
  },
  "wifi": {
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lādētāja īpašnieks nolēma nedalīt publisko WIFI tīklu tehnisku iemeslu dēļ"])},
    "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netālu no lādētāja ir pieejams kopīgs WIFI tīkls"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pieslēgties"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parole"])}
  },
  "cms": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atvainojiet, mēs gatavojam saturu."])}
  },
  "map": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atvainojiet,~bet šī lādētāja orientācijas karte šobrīd nav pieejama"])}
  }
}