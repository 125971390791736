export default {
  "aktualny-jazyk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["portugalčina"])},
  "ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pt"])},
  "extend-web": {
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais informações na página WEB"])}
  },
  "weather": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A previsão do tempo não está disponível no momento !"])},
    "at-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previsão do tempo por hora"])},
    "direct-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direção do vento"])},
    "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A chuva"])},
    "speed-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocidade do vento"])},
    "gust-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rajadas de vento"])},
    "press-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pressão do ar"])},
    "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nevando"])},
    "sunrise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nascer do sol"])},
    "sunset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pôr do sol"])},
    "cloudy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ensolarado"])},
    "cloudy-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["meio claro"])},
    "cloudy-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nublado"])},
    "cloudy-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quase nublado"])},
    "cloudy-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["completamente nublado"])}
  },
  "no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O carregador está inativo ou em serviço"])},
  "menu": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VOLTAR"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAPA"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LÍNGUAS"])},
    "weath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O CLIMA"])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REGIÃO"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WI-FI"])},
    "con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONECTOR"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AJUDA"])},
    "to-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu do aplicativo"])},
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta parte do aplicativo está temporariamente indisponível"])},
    "not-wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O carregador atualmente não suporta WIFI compartilhado"])}
  },
  "small-display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para sua funcionalidade, o aplicativo requer um display com resolução mínima: largura 800 e altura 600 pixels"])},
  "daysName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domingo"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segunda-feira"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terça-feira"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quarta-feira"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quinta-feira"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexta-feira"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sábado"])}
  },
  "monthsName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Janeiro"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fevereiro"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marchar"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abril"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poderia"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Junho"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Julho"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agosto"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setembro"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outubro"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novembro"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dezembro"])}
  },
  "wifi": {
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O proprietário do carregador decidiu não compartilhar a rede WIFI pública por motivos técnicos"])},
    "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uma rede WIFI compartilhada está disponível perto do carregador"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conecte-se"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha"])}
  },
  "cms": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desculpe, estamos preparando o conteúdo."])}
  },
  "map": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lamentamos,~mas o mapa de orientação para este carregador não está disponível no momento"])}
  }
}