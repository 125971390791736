//  this.$store.commit('[...]ToVuex', [...])
//  this.$store.state.[...]



import { createStore } from 'vuex'

export default createStore({
  state: {
    oCurrentCharger:          [],
    oFlagsFromThisCharger:    [],
    oSliders:                 [],
    oTechInfo:                [],
    oLastDataCharger:         [],
    oAllChargers:             [],

    //Content Management systems
    oRegionManageCMS:         [],
    oRegionLinesCMS:          [],
    oRegionInterTextsCMS:     [],
    //===========================

    oGetAllow:                [],
    oGetCloudy:               [],

    controlObjectSliders:     0,
    promoVideo:               '',

  },

  getters: {},

  mutations: {
    oCurrentChargerToVuex(state, oCurrentCharger) {
      state.oCurrentCharger = oCurrentCharger
    },      
    oFlagsFromThisChargerToVuex(state, oFlagsFromThisCharger) {
      state.oFlagsFromThisCharger = oFlagsFromThisCharger
    },      
    
    oSlidersToVuex(state, oSliders) {
      state.oSliders = oSliders
    },          
    controlObjectSlidersToVuex(state, controlObjectSliders) {
      state.controlObjectSliders = controlObjectSliders
    },          

    oTechInfoToVuex(state, oTechInfo) {
      state.oTechInfo = oTechInfo
    },      
    oLastDataChargerToVuex(state, oLastDataCharger) {
      state.oLastDataCharger = oLastDataCharger
    },          
    oContentManageToVuex(state, oContentManage) {
      state.oContentManage   = oContentManage
    },        
    
    //Content Management systems
    oRegionManageCMSToVuex(state, oRegionManageCMS) {
      state.oRegionManageCMS   = oRegionManageCMS
    },
    oRegionLinesCMSToVuex(state, oRegionLinesCMS) {
      state.oRegionLinesCMS   = oRegionLinesCMS
    },
    oRegionInterTextsCMSToVuex(state, oRegionInterTextsCMS) {
      state.oRegionInterTextsCMS   = oRegionInterTextsCMS
    },
    //===========================

    
    //All chrgers
    oAllChargersToVuex(state, oAllChargers) {
      state.oAllChargers   = oAllChargers
    },


    //weather
    
    oGetAllowToVuex(state, oGetAllow) {
      state.oGetAllow   = oGetAllow
    },    

    oGetCloudyToVuex(state, oGetCloudy) {
      state.oGetCloudy   = oGetCloudy
    },  


    //VIDEO
    promoVideoToVuex(state, promoVideo) {
      state.promoVideo   = promoVideo
    },  
    
    
  },

  actions: {},

  modules: {}
})
