export default {
  "aktualny-jazyk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chorvátčina"])},
  "ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hr"])},
  "extend-web": {
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Više informacija na WEB stranici"])}
  },
  "weather": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vremenska prognoza trenutno nije dostupna !"])},
    "at-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vremenska prognoza po satu"])},
    "direct-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smjer vjetra"])},
    "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiša"])},
    "speed-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brzina vjetra"])},
    "gust-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naleti vjetra"])},
    "press-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tlak zraka"])},
    "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pada snijeg"])},
    "sunrise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["izlazak sunca"])},
    "sunset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zalazak sunca"])},
    "cloudy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sunčano"])},
    "cloudy-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["napola jasno"])},
    "cloudy-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oblačno"])},
    "cloudy-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gotovo oblačno"])},
    "cloudy-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["potpuno oblačno"])}
  },
  "no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punjač nije aktivan ili je u servisu"])},
  "menu": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LEĐA"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KARTA"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JEZICI"])},
    "weath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VRIJEME"])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFO REGION"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WI-FI"])},
    "con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SPOJNICA"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POMOZITE"])},
    "to-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izbornik aplikacije"])},
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ovaj dio aplikacije je privremeno nedostupan"])},
    "not-wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punjač trenutno ne podržava zajednički WIFI"])}
  },
  "small-display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Za svoju funkcionalnost aplikacija zahtijeva zaslon minimalne rezolucije: širine 800 i visine 600 piksela"])},
  "daysName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nedjelja"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ponedjeljak"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["utorak"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["srijeda"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["četvrtak"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["petak"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subota"])}
  },
  "monthsName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["siječnja"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["veljača"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ožujak"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["travanj"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["svibanj"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lipanj"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["srpanj"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kolovoz"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rujan"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["listopad"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["studeni"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prosinac"])}
  },
  "wifi": {
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlasnik punjača odlučio je ne dijeliti javnu WIFI mrežu iz tehničkih razloga"])},
    "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zajednička WIFI mreža dostupna je u blizini punjača"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijaviti se"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lozinka"])}
  },
  "cms": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nažalost, pripremamo sadržaj."])}
  },
  "map": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žao nam je,~ali orijentacijska karta za ovaj punjač trenutno nije dostupna"])}
  }
}