export default {
  "aktualny-jazyk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["čeština"])},
  "ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cz"])},
  "extend-web": {
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Více informací na WEB stránce"])}
  },
  "weather": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Předpověď počasí není v současnosti dostupná !"])},
    "at-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Předpověď počasí po hodinách"])},
    "direct-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Směr větru"])},
    "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déšť"])},
    "speed-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rychlost větru"])},
    "gust-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nárazy větru"])},
    "press-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tlak vzduchu"])},
    "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sněžení"])},
    "sunrise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["východ slunce"])},
    "sunset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["západ slunce"])},
    "cloudy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slunečno"])},
    "cloudy-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["polojasno"])},
    "cloudy-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oblačno"])},
    "cloudy-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["téměř zataženo"])},
    "cloudy-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zcela zataženo"])}
  },
  "no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nabíječka je neaktivní, nebo probíhá servis"])},
  "menu": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZPĚT"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAPA"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JAZYKY"])},
    "weath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POČASÍ"])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFO REGION"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WI-FI"])},
    "con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KONEKTOR"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POMOC"])},
    "to-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu aplikace"])},
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tato část aplikace je dočasně nedostupná"])},
    "not-wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nabíječka v současnosti sdílenou WIFI nepodporuje"])}
  },
  "small-display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikace pro svoji funkčnost vyžaduje display s minimálním rozlišením: šířka 800 a výška 600 pixelů"])},
  "daysName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neděle"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pondělí"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úterý"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Středa"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čtvrtek"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pátek"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobota"])}
  },
  "monthsName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leden"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Únor"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Březen"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duben"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Květen"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Červen"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Červenec"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Srpen"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Září"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Říjen"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listopad"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosinec"])}
  },
  "wifi": {
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastník nabíječky se z technických důvodů rozhodl nesdílet veřejnou WIFI síť"])},
    "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V blízkosti nabíječky je k dispozici sdílená WIFI síť"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlášení"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo"])}
  },
  "cms": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omlouváme se, obsah připravujeme."])}
  },
  "map": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omlouváme se,~ale orientační mapa u této nabíječky není v současnosti dostupná"])}
  }
}