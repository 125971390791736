
import  axios   from    "axios"
import  SPEC    from    "@/assets/modules/Specials"

export default class dbProceduresFromApp {

constructor(App) {
    this.App        =   App
    this.SPEC       =   new SPEC(this.App)

    this.script     =   'dbProc.App.'
}    

readChargersForDebugger =   (methodName, appVariable)  =>  {

    let axios_ = methodName + ' / ' + this.script+'readChargersForDebugger()'
    let formData = this.SPEC.formData(axios_)  
    
    formData.append('command',      'proc-readChargersForDebugger')
    formData.append('exception',    '401q7OVXI9J4BQKDteL5stO9XsIK23')

    this.callProcViaAxios( axios_, formData, appVariable)
}


flagsFromCurrentCharger = (methodName, appVariable, fields)  =>  {   

    let axios_ = methodName + ' / ' + this.script+'flagsFromCurrentCharger()'
    let formData = this.SPEC.formData(axios_)    

    formData.append('command',  'proc-flagsFromCurrentCharger')    
    formData.append('fields',  fields)    
    this.callProcViaAxios( axios_, formData, appVariable)
}

getPromoVideo   =   (methodName, appVariable, fields)  =>  {   

    let axios_ = methodName + ' / ' + this.script+'getPromoVideo()'
    let formData = this.SPEC.formData(axios_)    

    formData.append('command',  'proc-getPromoVideo')    
    formData.append('fields',  fields)    
    this.callProcViaAxios( axios_, formData, appVariable)
}


getSlidersActiveOnly  =   (methodName, appVariable, fields)  =>  {   

    let axios_ = methodName + ' / ' + this.script+'getSlidersActiveOnly()'
    let formData = this.SPEC.formData(axios_)

    formData.append('command',  'proc-getSlidersActiveOnly')    
    formData.append('fields',  fields)    
    this.callProcViaAxios( axios_, formData, appVariable)        
}

connectRemotePoint   =   (methodName, appVariable)  =>  {
    //console.log('getCurrentRemotePoint :: ' + viewResponse)
    let axios_ = methodName + ' / ' + this.script+'connectRemotePoint()'
    let formData = this.SPEC.formData(axios_)

    formData.append('command',  'proc-connectRemotePoint')    
    formData.append('useAPI',   localStorage.useAPI)    
    //console.log(localStorage.useAPI)
    this.callProcViaAxios( axios_, formData, appVariable)        
}

callProcViaAxios = (axios_='', formData, appVariable='') =>  {
    //let oJSON
    //console.log(axios_)
    //console.log(localStorage.restAPI)

    var App = this.App
    axios.post(localStorage.restAPI, formData).then(response => {
        //console.log(response.data)

        if(!this.SPEC.isErrorResponseData(axios_, formData, response, appVariable.trim().length>0, false))  {
            if(appVariable.trim().length>0)    
                this.App[appVariable] = response.data                    
        }

    }).catch(function(error) {new SPEC(App).axiosErrorCatch('dbProc.js', error, formData, axios_)})

    /*
    .catch(function(error) {
        let _JSON = ''
        _JSON += '{'
        _JSON +=  '"error":"'+error.message+'",'
        _JSON +=  '"method":"' + axios_ + '",'
        if(tmplVariable.trim().length>0)    
            _JSON +=  '"templateVar":"' + tmplVariable + '",'
        _JSON +=  '"UID":"' + localStorage.netHeavenlyGate + '",'
        _JSON +=  '"project":"' + localStorage.projectName + '"}'
        oJSON = JSON.parse(_JSON)        

        console.log('=== AXIOS error: ===')
        console.log(oJSON.error)
        console.log(oJSON.method)

        if(typeof oJSON.templateVar!=='undefined')    
            if(oJSON.templateVar.trim().length>0)    
                console.log(oJSON.templateVar)

        console.log(oJSON.UID)
        console.log(oJSON.project)                
    })           
    */
}

}

