export default {
  "aktualny-jazyk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kazaština"])},
  "ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kz"])},
  "extend-web": {
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толық ақпарат WEB бетінде"])}
  },
  "weather": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ауа райы болжамы қазір қол жетімді емес !"])},
    "at-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сағаттық ауа райы болжамы"])},
    "direct-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жел бағыты"])},
    "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңбыр"])},
    "speed-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жел жылдамдығы"])},
    "gust-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Желдің екпіні"])},
    "press-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ауа қысымы"])},
    "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қар жауады"])},
    "sunrise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kүннің шығуы"])},
    "sunset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["күн батуы"])},
    "cloudy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kүн шуақты"])},
    "cloudy-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["жартылай таза"])},
    "cloudy-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бұлтты"])},
    "cloudy-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дерлік бұлтты"])},
    "cloudy-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["толығымен бұлтты"])}
  },
  "no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарядтағыш белсенді емес немесе қызмет көрсетуде"])},
  "menu": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["КЕРІ"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["КАРТА"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ТІЛДЕР"])},
    "weath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["АУА РАЙЫ"])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["АҚПАРАТ АЙМАҚ"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["СЫМСЫЗ ДӘЛДIК"])},
    "con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ҚОННЕКТОР"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["КӨМЕКТЕСІҢДЕР"])},
    "to-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қолданба мәзірі"])},
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қолданбаның бұл бөлігі уақытша қолжетімсіз"])},
    "not-wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қазіргі уақытта зарядтағыш ортақ WIFI-ды қолдамайды"])}
  },
  "small-display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оның функционалдығы үшін қолданба ең аз ажыратымдылығы бар дисплейді қажет етеді: ені 800 және биіктігі 600 пиксель"])},
  "daysName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жексенбі"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дүйсенбі"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сейсенбі"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сәрсенбі"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бейсенбі"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["жұма"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сенбі"])}
  },
  "monthsName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["қаңтар"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ақпан"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["наурыз"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сәуір"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["мамыр"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["маусым"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["шілде"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["тамыз"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["қыркүйек"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["қазан"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["қараша"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["желтоқсан"])}
  },
  "wifi": {
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарядтағыштың иесі техникалық себептерге байланысты жалпыға қолжетімді WI-FI желісін бөліспеу туралы шешім қабылдады"])},
    "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарядтағыштың жанында ортақ WI-FI желісі бар"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кіру"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпия сөз"])}
  },
  "cms": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кешіріңіз, біз мазмұнды дайындап жатырмыз."])}
  },
  "map": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кешіріңіз,~бірақ бұл зарядтағышқа арналған бағдар картасы қазір қолжетімді емес"])}
  }
}