export default {
  "aktualny-jazyk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rumunčina"])},
  "ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ro"])},
  "extend-web": {
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mai multe informatii pe pagina WEB"])}
  },
  "weather": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Momentan, prognoza meteo nu este disponibilă !"])},
    "at-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prognoza meteo pe oră"])},
    "direct-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directia vantului"])},
    "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ploaia"])},
    "speed-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viteza vântului"])},
    "gust-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rafale de vânt"])},
    "press-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presiunea aerului"])},
    "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zăpadă"])},
    "sunrise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["răsărit"])},
    "sunset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["apus de soare"])},
    "cloudy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Însorit"])},
    "cloudy-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe jumătate clar"])},
    "cloudy-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noros"])},
    "cloudy-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aproape înnorat"])},
    "cloudy-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["complet înnorat"])}
  },
  "no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încărcătorul este inactiv sau în curs de service"])},
  "menu": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ÎNAPOI"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HARTĂ"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LIMBURI"])},
    "weath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VREMEA"])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFO REGIUNE"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WI-FI"])},
    "con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONECTOR"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AJUTOR"])},
    "to-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meniul aplicației"])},
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Această parte a aplicației este temporar indisponibilă"])},
    "not-wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["În prezent, încărcătorul nu acceptă WIFI partajat"])}
  },
  "small-display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pentru funcționalitatea sa, aplicația necesită un afișaj cu o rezoluție minimă: lățime 800 și înălțime 600 pixeli"])},
  "daysName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duminică"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luni"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marţi"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miercuri"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joi"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vineri"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sâmbătă"])}
  },
  "monthsName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ianuarie"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Februarie"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martie"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprilie"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mai"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iunie"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iulie"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Septembrie"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Octombrie"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noiembrie"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decembrie"])}
  },
  "wifi": {
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proprietarul încărcătorului a decis să nu partajeze rețeaua WIFI publică din motive tehnice"])},
    "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O rețea WIFI partajată este disponibilă lângă încărcător"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola"])}
  },
  "cms": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne pare rău, pregătim conținutul."])}
  },
  "map": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne pare rău,~dar harta de orientare pentru acest încărcător nu este disponibilă momentan"])}
  }
}