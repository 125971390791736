export default {
  "aktualny-jazyk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["angličtina"])},
  "ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en"])},
  "extend-web": {
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More information on the WEB page"])}
  },
  "weather": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The weather forecast is currently not available !"])},
    "at-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hourly weather forecast"])},
    "direct-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wind direction"])},
    "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The rain"])},
    "speed-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wind speed"])},
    "gust-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gusts of wind"])},
    "press-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Air pressure"])},
    "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snowing"])},
    "sunrise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sunrise"])},
    "sunset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sunset"])},
    "cloudy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sunny"])},
    "cloudy-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["half clear"])},
    "cloudy-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cloudy"])},
    "cloudy-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["almost cloudy"])},
    "cloudy-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["completely cloudy"])}
  },
  "no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The charger is inactive or undergoing service"])},
  "menu": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BACK"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAP"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LANGUAGES"])},
    "weath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["THE WEATHER"])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFO REGION"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WI-FI"])},
    "con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONNECTOR"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HELP"])},
    "to-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application menu"])},
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This part of the application is temporarily unavailable"])},
    "not-wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The charger currently does not support shared WIFI"])}
  },
  "small-display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For its functionality, the application requires a display with a minimum resolution: width 800 and height 600 pixels"])},
  "daysName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunday"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wednesday"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursday"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friday"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday"])}
  },
  "monthsName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["February"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["July"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December"])}
  },
  "wifi": {
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The owner of the charger decided not to share the public WIFI network for technical reasons"])},
    "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A shared WIFI network is available near the charger"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])}
  },
  "cms": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, we are preparing the content."])}
  },
  "map": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are sorry,~but the orientation map for this charger is not available at the moment"])}
  }
}