export default {
  "aktualny-jazyk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fínčina"])},
  "ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fi"])},
  "extend-web": {
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisätietoa WEB-sivulla"])}
  },
  "weather": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sääennuste ei ole tällä hetkellä saatavilla !"])},
    "at-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunneittain sääennuste"])},
    "direct-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuulen suunta"])},
    "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sade"])},
    "speed-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuulen nopeus"])},
    "gust-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuulenpuuskat"])},
    "press-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilmanpaine"])},
    "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunta sataa"])},
    "sunrise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["auringonnousu"])},
    "sunset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["auringonlasku"])},
    "cloudy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aurinkoinen"])},
    "cloudy-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["puoliksi selvä"])},
    "cloudy-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pilvinen"])},
    "cloudy-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["melkein pilvistä"])},
    "cloudy-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["täysin pilvistä"])}
  },
  "no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laturi ei ole aktiivinen tai sitä huolletaan"])},
  "menu": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TAKAISIN"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KARTTA"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KIELI "])},
    "weath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SÄÄ"])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFO-ALUE"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WI-FI"])},
    "con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LIITIN"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AUTA"])},
    "to-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sovellusvalikko"])},
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä sovelluksen osa ei ole tilapäisesti käytettävissä"])},
    "not-wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laturi ei tällä hetkellä tue jaettua WIFI-yhteyttä"])}
  },
  "small-display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toimivuutta varten sovellus vaatii näytön, jonka resoluutio on vähintään: leveys 800 ja korkeus 600 pikseliä"])},
  "daysName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sunnuntai"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maanantai"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tiistai"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keskiviikko"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["torstai"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["perjantai"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lauantai"])}
  },
  "monthsName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tammikuu"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["helmikuu"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maaliskuuta"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["huhtikuu"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["saattaa"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kesäkuuta"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["heinäkuu"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["elokuu"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["syyskuu"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lokakuu"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["marraskuu"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["joulukuu"])}
  },
  "wifi": {
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laturin omistaja päätti olla jakamatta julkista WIFI-verkkoa teknisistä syistä"])},
    "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaettu WIFI-verkko on käytettävissä laturin lähellä"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjaudu sisään"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salasana"])}
  },
  "cms": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitettavasti valmistellaan sisältöä."])}
  },
  "map": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olemme pahoillamme,~mutta tämän laturin suuntakartta ei ole tällä hetkellä saatavilla"])}
  }
}